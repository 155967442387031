import React from "react";
import { Flex, Button, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import WithAppLayout from "../../../components/WithAppLayout";
import PageTitle from "../../../components/PageTitle";
import { CSVFilePicker } from "../../../components/Filepicker";
import MoblyzeApolloClient from "../../../services/MoblyzeApolloClient";
import { IMPORT_BULLHORN_JOBS_MUTATION } from "../../../graphql/jobs_admin";
import { UNCURATED_JOBS_PATH, CLIENT_DASHBOARD_PATH } from "../../../routes";

function ImportJobs({ currentUser }) {
  const [csvFile, setCSVFile] = React.useState(null);
  const toast = useToast();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);
  if (currentUser?.agency?.type === "corporate") {
    navigate(CLIENT_DASHBOARD_PATH);
  }

  const handleSubmit = () => {
    if (!csvFile) {
      return;
    }

    setIsLoading(true);

    MoblyzeApolloClient.mutate({
      mutation: IMPORT_BULLHORN_JOBS_MUTATION,
      variables: {
        csvFile,
      },
    })
      .then((res) => {
        const numJobs = res?.data?.importBullhornJobs?.length || 0;
        toast({
          title: "Success",
          description: `${numJobs} new jobs imported`,
          status: "success",
        });

        navigate(UNCURATED_JOBS_PATH);
      })
      .catch((err) => {
        console.log(err);

        toast({
          title: "Error",
          description: "There was an error importing jobs: " + err.message,
          status: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Flex
        h="100%"
        direction="column"
        maxW={{
          base: "100%",
          md: "50%",
        }}
      >
        <PageTitle title="Import Bullhorn Jobs" />

        <Flex h="20px" />
        <a
          href="https://docs.google.com/document/d/10FlfkKjWsFeAYmk559p1LtEM_tt872wC72BaUeenz-c/edit"
          target="_blank"
          mt="4"
          rel="noreferrer"
        >
          Follow these{" "}
          <span style={{ textDecoration: "underline" }}>instructions</span> for
          importing jobs from Bullhorn. Only new jobs (that don't already have a
          Bullhorn ID in Moblyze) will be imported.
        </a>

        <Flex h="40px" />

        <CSVFilePicker name="csvFile" setCSVValue={setCSVFile} />
        <Button onClick={handleSubmit} mt="5" isLoading={isLoading}>
          Import New Jobs
        </Button>
      </Flex>
    </>
  );
}

export default WithAppLayout(ImportJobs);
