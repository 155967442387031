// Translation lookup table
const translations = {
    "Jobs": {
      agency: "Jobs",
      corporate: "Jobs"
    },
    "Clients": {
      agency: "Clients",
      corporate: "Departments"
    },
    "Client": {
      agency: "Client",
      corporate: "Department"
    },
    "Admins": {
      agency: "Admins",
      corporate: "Admins"
    },
    "Workers": {
      agency: "Workers",
      corporate: "Candidates"
    },
    "Uncurated": {
      agency: "Uncurated",
      corporate: "Pending"
    },
    "Active": {
      agency: "Active",
      corporate: "Ongoing"
    },
    "Archived": {
      agency: "Archived",
      corporate: "Closed"
    },
    "Add New Job": {
      agency: "Add New Job",
      corporate: "Post New Opening"
    },
    "Import Jobs": {
      agency: "Import Jobs",
      corporate: "Bulk Import"
    },
    "All": {
      agency: "All",
      corporate: "View All"
    },
    "Add New Client": {
      agency: "Add New Client",
      corporate: "Add New Department"
    },
    "Invite": {
      agency: "Invite",
      corporate: "Send Invite"
    },

  };

  // Translation function
  const __ = (label, companyType) => {
    return translations[label]?.[companyType] || label;
  };

  export default __;