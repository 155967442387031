import { createColumnHelper } from "@tanstack/react-table";

import {
  EditUncuratedJobRowAction,
  RecruiterJobReportApprovedColumn,
  RecruiterJobReportInterestedColumn,
} from "./DataTable";
import { formatStartDate } from "../../helpers/strings";
import __ from "../../helpers/translations"

const columnHelper = createColumnHelper();

export const jobsColumns = [
  columnHelper.accessor("startDate", {
    cell: (info) => formatStartDate(info.getValue()),
    header: "Start Date",
  }),
  columnHelper.accessor("bullhornId", {
    cell: (info) => info.getValue(),
    header: "Bullhorn ID",
  }),
  columnHelper.accessor("category", {
    cell: (info) => info.getValue(),
    header: "Job Title",
  }),
  columnHelper.accessor("title", {
    cell: (info) => info.getValue(),
    header: "Position",
  }),

  columnHelper.accessor("employerName", {
    cell: (info) => info.getValue(),
    header: "Client",
  }),

  columnHelper.accessor("location", {
    cell: (info) => info.getValue(),
    header: "Location",
  }),
];

export const activeJobsColumns = [
  columnHelper.accessor("startDate", {
    cell: (info) => formatStartDate(info.getValue()),
    header: "Start Date",
  }),
  columnHelper.accessor("bullhornId", {
    cell: (info) => info.getValue(),
    header: "Bullhorn ID",
  }),
  columnHelper.accessor("category", {
    cell: (info) => info.getValue(),
    header: "Job Title",
  }),
  columnHelper.accessor("employerName", {
    cell: (info) => info.getValue(),
    header: "Client",
  }),
  columnHelper.accessor("recruiterJobReport.interestedCount", {
    id: "interestedCount",
    cell: (props) => <RecruiterJobReportInterestedColumn row={props.row} />,
    header: "Interested",
  }),
  columnHelper.accessor("recruiterJobReport.approvedByClientCount", {
    id: "approvedByClientCount",
    cell: (props) => <RecruiterJobReportApprovedColumn row={props.row} />,
    header: "Approved",
  }),
  columnHelper.accessor("location", {
    cell: (info) => info.getValue(),
    header: "Location",
  }),
];

export const uncuratedJobsColumns = (refetch, agencyType) => [
  columnHelper.accessor("startDate", {
    cell: (info) => info.getValue(),
    header: "Start Date",
  }),
  columnHelper.accessor("bullhornId", {
    cell: (info) => info.getValue(),
    header: "Bullhorn ID",
  }),
  columnHelper.accessor("vertical", {
    cell: (info) => info.getValue(),
    header: "Job Title",
  }),
  columnHelper.accessor("jobTitle", {
    cell: (info) => info.getValue(),
    header: "Position",
  }),

  columnHelper.accessor("client", {
    cell: (info) => info.getValue(),
    header: __("Uncurated", agencyType),
  }),

  columnHelper.accessor("region", {
    cell: (info) => info.getValue(),
    header: "Location",
  }),

  columnHelper.display({
    id: "actions",
    cell: (props) => (
      <EditUncuratedJobRowAction row={props.row} refetch={refetch} />
    ),
    size: 1,
  }),
];

export const settingsColumns = (refetch) => [
  columnHelper.accessor("name", {
    cell: (info) => info.getValue(),
    header: "Name",
  }),

  columnHelper.accessor("slugId", {
    cell: (info) => info.getValue(),
    header: "ID",
  }),

];

export const clientOrgColumns = [
  columnHelper.accessor("name", {
    cell: (info) => info.getValue(),
    header: "Client",
    size: 1,
  }),
  columnHelper.accessor("clientOrganizationReport.numActiveJobs", {
    cell: (info) => info.getValue(),
    header: "# Jobs",
    size: 1,
  }),
  columnHelper.accessor("clientOrganizationReport.locationsDescription", {
    cell: (info) => info.getValue(),
    header: "Locations",
  }),
];

export const recruitersColumns = [
  columnHelper.accessor("firstName", {
    cell: (info) => info.getValue(),
    header: "First Name",
    size: 1,
  }),
  columnHelper.accessor("lastName", {
    cell: (info) => info.getValue(),
    header: "Last Name",
    size: 1,
  }),
  columnHelper.accessor("email", {
    cell: (info) => info.getValue(),
    header: "Email",
    size: 1,
  }),
];

export const candidatesColumns = [
  columnHelper.accessor("firstName", {
    cell: (info) => info.getValue(),
    header: "First Name",
    size: 1,
  }),
  columnHelper.accessor("lastName", {
    cell: (info) => info.getValue(),
    header: "Last Name",
    size: 1,
  }),
  columnHelper.accessor("email", {
    cell: (info) => info.getValue(),
    header: "Email",
    size: 1,
  }),
  columnHelper.accessor("primaryPhone", {
    cell: (info) => info.getValue(),
    header: "Phone",
    size: 1,
  }),
  columnHelper.accessor(
    (item) =>
      !!item?.candidateProfile?.resumeUrl &&
      item?.candidateProfile?.resumeUrl !== "",
    {
      cell: (info) => (info.getValue() ? "Yes" : "No"),
      header: "Has Resume?",
      size: 1,
    }
  ),
  columnHelper.accessor("id", {
    cell: (info) => info.getValue(),
    header: "ID",
    size: 0,
  }),
];
