import React from "react";
import { useNavigate } from "react-router-dom";

import WithAppLayout from "../../../components/WithAppLayout";
import PageTableWithSearch from "../../../components/PageTableWithSearch/PageTableWithSearch";

import { uncuratedJobsColumns } from "../../../components/PageTableWithSearch/tableDataHelper";
import WithQuery from "../../../components/WithQuery";
import { UNCURATED_JOBS_QUERY } from "../../../graphql/jobs_admin";
import { CURATE_JOB_PATH } from "../../../routes";
import { LoadingIndicator } from "../../../components/LoadingIndicator";
import __ from "../../../helpers/translations"

function UncuratedJobsPageInner({
  data,
  error,
  loading,
  refetch,
  currentUser,
}) {
  const navigate = useNavigate();

  if (loading) {
    return <LoadingIndicator />;
  }
  const agencyType = currentUser?.agency?.type;

  return (
    <PageTableWithSearch
      title={`${__("Uncurated", agencyType)} Jobs ${
        data?.agencyUncuratedJobs?.length
          ? ` (${data?.agencyUncuratedJobs?.length})`
          : ""
      }`}
      columns={uncuratedJobsColumns(refetch, agencyType)}
      data={data?.agencyUncuratedJobs || []}
      onClickRow={(moblyzeJob) => {
        navigate(CURATE_JOB_PATH.replace(":id", moblyzeJob.id));
      }}
    />
  );
}

function PageComponent({ children, currentUser }) {
  return WithQuery(UncuratedJobsPageInner, UNCURATED_JOBS_QUERY, null, {
    children,
    currentUser,
  })();
}

export default WithAppLayout(PageComponent);
