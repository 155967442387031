import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Flex, useToast, Box, Text, Icon, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Divider, Stack, Modal, ModalOverlay, ModalHeader, ModalCloseButton, ModalBody, Image, ModalContent, SimpleGrid, Tag } from "@chakra-ui/react";
import { ReactComponent as LeftArrow } from "../../../assets/icons/left-arrow.svg";
import { CheckCircleIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { sortBy } from "lodash";

import WithAppLayout from "../../../components/WithAppLayout";
import PageTitle from "../../../components/PageTitle";
import WithQuery from "../../../components/WithQuery";

import {
  CREATE_JOB_MUTATION,
  JOB_CREATE_PAGE_QUERY,
  UPLOAD_JOB_COVER_IMAGE_MUTATION,
  UPDATE_JOB_MUTATION,
  JOB_EDIT_PAGE_QUERY,
  GET_SKILLS_BY_ROLE_QUERY,
  GET_CERTIFICATIONS_BY_ROLE_QUERY
} from "../../../graphql/jobs_admin";

import MoblyzeApolloClient from "../../../services/MoblyzeApolloClient";
import { JOB_DETAILS_PATH } from "../../../routes";
import { IsProduction } from "../../../helpers/config";
import { usePrevious } from "../../../helpers/hooks";

import {  BasicInput,  ImageUpload,  MultiSelectInput,  SelectInput} from "../../../components/HookFormsInputs";
import AddVesselModal from "../../../components/AddVesselModal";
import colors from "../../../helpers/colors";
import { STORAGE_KEYS } from '../../../constants/storage';

import image1 from '../../../assets/img/predefinedImages/predefinedImages-1.png';
import image2 from '../../../assets/img/predefinedImages/predefinedImages-2.png';
import image3 from '../../../assets/img/predefinedImages/predefinedImages-3.png';
import image4 from '../../../assets/img/predefinedImages/predefinedImages-4.png';
import image5 from '../../../assets/img/predefinedImages/predefinedImages-5.png';
import image6 from '../../../assets/img/predefinedImages/predefinedImages-6.png';
import image7 from '../../../assets/img/predefinedImages/predefinedImages-7.png';
import image8 from '../../../assets/img/predefinedImages/predefinedImages-8.png';
import image9 from '../../../assets/img/predefinedImages/predefinedImages-9.png';

const employmentTypeOptions = [
  { value: "FullTime", label: "Full Time" },
  { value: "PartTime", label: "Part Time" },
  { value: "Contract", label: "Contract" },
];

const payFrequencyOptions = [
  { value: "Hourly", label: "Per Hour" },
  { value: "Daily", label: "Per Day" },
  { value: "Weekly", label: "Per Week" },
  { value: "Monthly", label: "Per Month" },
  { value: "Yearly", label: "Per Year" },
];

const payCurrencyOptions = [
  { value: "GBP", label: "GBP" },
  { value: "USD", label: "USD" },
];

const getFormValuesFromMoblyzeJob = ({  moblyzeJobInner,  clientOrganizationOptions,  vesselOptions,  data}) => {

  const startDate = moblyzeJobInner?.startDate
    ? new Date(moblyzeJobInner?.startDate).toISOString().slice(0, 10)
    : null;

  return {
    bullhornId: moblyzeJobInner?.bullhornId,
    payRateValue: moblyzeJobInner?.payRateValue
      ? moblyzeJobInner?.payRateValue.toString()
      : "",
    payFrequencySelectedOption: moblyzeJobInner?.payFrequency
      ? payFrequencyOptions.find(
          (option) => option.value === moblyzeJobInner?.payFrequency
        )
      : null,
    payCurrencySelectedOption: moblyzeJobInner?.payCurrency
      ? payCurrencyOptions.find(
          (option) => option.value === moblyzeJobInner?.payCurrency
        )
      : null,
    clientOrganizationSelectedOption: moblyzeJobInner?.clientOrganizationId
      ? clientOrganizationOptions.find(
          (option) => option.value === moblyzeJobInner?.clientOrganizationId
        )
      : null,
    clientContactName: moblyzeJobInner?.employerContactName,
    clientContactEmail: moblyzeJobInner?.employerContactEmail,
    clientContactPhone: moblyzeJobInner?.employerContactPhone,
    agencyContactName: moblyzeJobInner?.agencyContactName,
    agencyContactEmail: moblyzeJobInner?.agencyContactEmail,
    agencyContactPhone: moblyzeJobInner?.agencyContactPhone,
    jobCategory: moblyzeJobInner?.category,
    jobPositionTitle: moblyzeJobInner?.title,
    jobLocationDescription: moblyzeJobInner?.location,
    duration: moblyzeJobInner?.duration,
    startDate: startDate,
    moreRequirements: moblyzeJobInner?.requirementsDescription,
    keyResponsibilities: moblyzeJobInner?.responsibilitiesDescription,
    numOpenings: moblyzeJobInner?.numOpenings,
    hiredInstructions: moblyzeJobInner?.hiredInstructions,
    siteAddress: moblyzeJobInner?.siteAddress,
    employmentTypeSelectedOption: moblyzeJobInner?.employmentType
      ? employmentTypeOptions.find(
          (option) => option.value === moblyzeJobInner?.employmentType
        )
      : null,
    vesselSelectedOption: moblyzeJobInner?.vesselId
      ? vesselOptions.find(
          (option) => option.value === moblyzeJobInner?.vesselId
        )
      : null,
    jobLocationSelectedOptions: moblyzeJobInner?.workLocations?.map(
      (location) => ({
        value: location.id,
        label: location.name,
      })
    ),
    jobRolesSelectedOptions: moblyzeJobInner?.roles?.map((role) => ({
      value: role.id,
      label: role.name,
    })),
    requiredSkillsOptions: data?.getSkills?.map((skill) => ({
      value: skill.id,
      label: skill.name,
    })),
    requiredCertifications: data?.getCertificationsByMoblyzeJobId?.map((cert) => ({
      value: cert.id,
      label: cert.name,
    })),
  };
};

export function CreateEditJobForm({  loading,  data,  error,  refetch,  currentUser,  isCuration}) {
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(null);
  const [currentStep, setCurrentStep] = React.useState(() => {
    const savedStep = localStorage.getItem(STORAGE_KEYS.CURRENT_STEP);
    return savedStep ? parseInt(savedStep) : 1;
  });

  // Add useEffect to save currentStep to localStorage
  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.CURRENT_STEP, currentStep.toString());
  }, [currentStep]);

  // Add handlers for each step's form submission
  const onStepOneSubmit = (data) => {
    localStorage.setItem(STORAGE_KEYS.STEP_1, JSON.stringify(data));
    setCurrentStep(2);
  };

  const onStepTwoSubmit = (data) => {
    // Convert File object to serializable format for localStorage
    const fileData = data.jobCoverImage instanceof File ? {
      name: data.jobCoverImage.name,
      lastModified: data.jobCoverImage.lastModified,
      size: data.jobCoverImage.size,
      type: data.jobCoverImage.type
    } : null;

    localStorage.setItem(STORAGE_KEYS.STEP_2, JSON.stringify({
      ...data,
      jobCoverImage: fileData
    }));
    setCurrentStep(3);
  };

  const onStepThreeSubmit = (data) => {
    localStorage.setItem(STORAGE_KEYS.STEP_3, JSON.stringify(data));
    setCurrentStep(4);
  };

  const handleFinish = (e) => {
    e.preventDefault();
    handleSubmit(onStepFourSubmit)(e); // Call the form submission
  };

  // Add function to clear storage
  const clearFormStorage = () => {
    localStorage.removeItem(STORAGE_KEYS.STEP_1);
    localStorage.removeItem(STORAGE_KEYS.STEP_2);
    localStorage.removeItem(STORAGE_KEYS.STEP_3);
    localStorage.removeItem(STORAGE_KEYS.STEP_4);
    localStorage.removeItem(STORAGE_KEYS.CURRENT_STEP);
  };

  const handleCancel = () => {
    clearFormStorage(); // Clear storage on cancel
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = "/";
    }
  };

  const isCreate = !data?.moblyzeJob || isCuration;
  const moblyzeJob = isCuration
    ? data?.bullhornJob?.moblyzeJobShell
    : data?.moblyzeJob;

  const prevProps = usePrevious({ moblyzeJob });

  const toast = useToast();

  const navigate = useNavigate();

  const clientOrganizationOptions = data?.getClientOrganizations?.map(
    (client) => ({
      value: client.id,
      label: client.name,
    })
  );

  const clientOrganizationOptionsSorted = sortBy(
    clientOrganizationOptions,
    (o) => o.label
  );

  const jobLocationOptions = data?.candidateWorkLocations?.map((location) => ({
    value: location.id,
    label: location.name,
  }));

  const jobRoleOptions = data?.candidateRoles?.map((role) => ({
    value: role.id,
    label: role.name,
  }));

  const jobRoleOptionsSorted = sortBy(jobRoleOptions, (o) => o.label);

  const vesselOptions = data?.getVessels?.map((vessel) => ({
    value: vessel.id,
    label: vessel.name,
  }));

  const vesselOptionsSorted = sortBy(vesselOptions, (o) => o.label);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors, isValid, isDirty, isSubmitting, }
  } = useForm({
    defaultValues: getFormValuesFromMoblyzeJob({
      moblyzeJobInner: moblyzeJob,
      clientOrganizationOptions,
      vesselOptions,
      data,
    }),
    mode: 'onChange', // This enables real-time validation
  });

  // Add this helper function to define fields for each step
  const getStepFields = (step) => {
    switch (step) {
      case 1:
        return [
          'clientOrganizationSelectedOption',
          'clientContactName',
          'clientContactEmail',
          'clientContactPhone',
          'agencyContactName',
          'agencyContactEmail',
          'agencyContactPhone',
          'bullhornId'
        ];
      case 2:
        return [
          'jobRolesSelectedOptions',
          'requiredSkillsOptions',
          'employmentTypeSelectedOption',
          'jobLocationDescription',
          'jobLocationSelectedOptions',
          'startDate',
          'numOpenings',
          'requiredCertifications',
          'jobCategory',
          'jobPositionTitle',
          'duration',
          'moreRequirements',
          'keyResponsibilities',
          'jobCoverImage'
        ];
      case 3:
        return [
          'payCurrencySelectedOption',
          'payRateValue',
          'payFrequencySelectedOption'
        ];
      case 4:
        return [
          'hiredInstructions',
          'siteAddress',
          'vesselSelectedOption'
        ];
      default:
        return [];
    }
  };

  // Modify the useEffect that loads saved form data
  useEffect(() => {
    const savedStepData = JSON.parse(localStorage.getItem(`createJobStep${currentStep}`) || '{}');
    if (Object.keys(savedStepData).length > 0) {
      reset(savedStepData, { keepDefaultValues: true });
    }
  }, [currentStep, reset]);

  // Modify the navigation handlers to save only relevant fields
  const handleNext = (e) => {
    e.preventDefault();
    const formData = getValues();
    const stepFields = getStepFields(currentStep);

    // Save current step data
    const stepData = {};
    stepFields.forEach(field => {
      if (formData[field] !== undefined) {
        stepData[field] = formData[field];
      }
    });

    localStorage.setItem(
      `createJobStep${currentStep}`,
      JSON.stringify(stepData)
    );

    // Load next step data if it exists
    const nextStep = currentStep + 1;
    const nextStepData = JSON.parse(localStorage.getItem(`createJobStep${nextStep}`) || '{}');

    if (Object.keys(nextStepData).length > 0) {
      reset(nextStepData, { keepDefaultValues: true });
    }

    setCurrentStep(nextStep);
  };

  // Handle previous step
  const handlePrevious = (e) => {
    e.preventDefault();
    const formData = getValues();
    const stepFields = getStepFields(currentStep);

    // Save current step data
    const stepData = {};
    stepFields.forEach(field => {
      if (formData[field] !== undefined) {
        stepData[field] = formData[field];
      }
    });

    localStorage.setItem(
      `createJobStep${currentStep}`,
      JSON.stringify(stepData)
    );

    // Load previous step data
    const previousStep = currentStep - 1;
    const previousStepData = JSON.parse(localStorage.getItem(`createJobStep${previousStep}`) || '{}');

    if (Object.keys(previousStepData).length > 0) {
      reset(previousStepData, { keepDefaultValues: true });
    }
    setCurrentStep(previousStep);
  };

  const [skillOptions, setSkillOptions] = React.useState([]);
  const [certificationOptions, setCertificationOptions] = React.useState([]);
  const [selectedCertifications, setSelectedCertifications] = React.useState([]);

  const selectedRoles = useWatch({
    control,
    name: "jobRolesSelectedOptions",
    defaultValue: [],
  });

  const selectedSkills = useWatch({
    control,
    name: "requiredSkillsOptions",
    defaultValue: [],
  });

  useEffect(() => {
    const selectedRoleIds = selectedRoles?.map(role => role.value) || [];
    const selectedSkillIds = selectedSkills?.map(skill => skill.value) || [];

    if (selectedRoleIds.length > 0) {
      // Fetch skills for selected roles
      MoblyzeApolloClient.query({
        query: GET_SKILLS_BY_ROLE_QUERY,
        variables: { roleId: selectedRoleIds }
      }).then(result => {
        const skills = result.data.getSkillsByCandidateRoleID.map(skill => ({
          value: skill.id,
          label: skill.name,
        }));
        setSkillOptions(sortBy(skills, o => o.label));
      });

      // Fetch certifications for selected roles
      MoblyzeApolloClient.query({
        query: GET_CERTIFICATIONS_BY_ROLE_QUERY,
        variables: { roleId: selectedRoleIds }
      }).then(result => {
        const certifications = result.data.getCertificationsByCandidateRoleID.map(cert => ({
          value: cert.id,
          label: cert.name,
        }));
        setCertificationOptions(sortBy(certifications, o => o.label));
      });
    } else {
      // Clear options when no roles are selected
      setSkillOptions([]);
      setCertificationOptions([]);
    }
  }, [selectedRoles, selectedSkills]);

  useEffect(() => {
    if (moblyzeJob?.requiredCertifications) {
      setSelectedCertifications(moblyzeJob.requiredCertifications.map(cert => ({
        value: cert.id,
        label: cert.name,
      })));
    }

    if (!moblyzeJob) {
      return;
    }

    // only run if last moblyze job was null
    if (prevProps?.moblyzeJob != null) {
      return;
    }

    reset(
      getFormValuesFromMoblyzeJob({
        moblyzeJobInner: moblyzeJob,
        clientOrganizationOptions,
        vesselOptions,
        data,
      })
    );
    // intentiaonlly leave out clientOrganizationOptions, vesselOptions as dependencies
    // they cause infinite loop set states
  }, [moblyzeJob]);

  const uploadJobCoverImage = async ({ imageFile, moblyzeJobId }) => {
    if (!imageFile) {
      return;
    }

    const r = await MoblyzeApolloClient.mutate({
      mutation: UPLOAD_JOB_COVER_IMAGE_MUTATION,
      variables: { moblyzeJobId, imageFile },
    });

    toast({
      title: "Job Cover Image uploaded",
      status: "success",
    });
  };

  // Add this utility function to convert URL to File
  const urlToFile = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const filename = url.split('/').pop() || 'cover-image.jpg';
      return new File([blob], filename, { type: blob.type });
    } catch (error) {
      console.error('Error converting URL to File:', error);
      return null;
    }
  };

  // Modify the createJobAndUploadLogo function
  const createJobAndUploadLogo = async ({ variables, imageFile }) => {
    try {
      const r = await MoblyzeApolloClient.mutate({
        mutation: CREATE_JOB_MUTATION,
        variables,
      });

      const moblyzeJobId = r.data.createJob.id;

      await uploadJobCoverImage({ moblyzeJobId, imageFile });

      toast({
        title: "Job created!",
        status: "success",
      });

      // navigate to new client
      navigate(JOB_DETAILS_PATH.replace(":id", moblyzeJobId));
    } catch (e) {
      alert("Error creating job " + e.message);
    }
  };

  // Similarly modify the updateJobAndUploadLogo function
  const updateJobAndUploadLogo = async ({
    variables,
    imageFile,
    moblyzeJobId,
  }) => {
    try {
      const r = await MoblyzeApolloClient.mutate({
        mutation: UPDATE_JOB_MUTATION,
        variables: {
          ...variables,
          imageFile: imageFile
        },
      });

      await uploadJobCoverImage({
        moblyzeJobId,
        imageFile: imageFile
      });

      toast({
        title: "Job updated!",
        status: "success",
      });

      navigate(JOB_DETAILS_PATH.replace(":id", moblyzeJobId));
    } catch (e) {
      toast({
        title: "Error updating job",
        subtitle: e.message,
        status: "error",
      });
    }
  };

  const [newVesselId, setNewVesselId] = React.useState(null);

  const handleNewVessselIdSelectedFromModal = (vesselId) => {
    setNewVesselId(vesselId);

    setValue(
      "vesselSelectedOption",
      vesselOptions.find((option) => option.value === vesselId)
    );
  };

  React.useEffect(() => {
    if (newVesselId) {
      setValue(
        "vesselSelectedOption",
        vesselOptions.find((option) => option.value === newVesselId)
      );
    }
  }, [newVesselId, setValue, vesselOptions]);

  if (loading || error) {
    return null;
  }

  let title = "";
  if (isCuration) {
    title = "Curate Job";
  } else if (isCreate) {
    title = "Add New Job";
  } else {
    title = "Edit Job";
  }

  const handleBreadcrumbClick = (stepNumber) => {
    // Only allow navigation to previous steps in edit mode
    if (!isCreate && stepNumber < currentStep) {
      const formData = getValues();
      const stepFields = getStepFields(currentStep);

      const stepData = {};
      stepFields.forEach(field => {
        if (formData[field] !== undefined) {
          stepData[field] = formData[field];
        }
      });

      localStorage.setItem(
        `createJobStep${currentStep}`,
        JSON.stringify(stepData)
      );

      setCurrentStep(stepNumber);
    }
  };

  const breadcrumbItems = [
    { step: 1, label: 'Department Details' },
    { step: 2, label: 'Job Details' },
    { step: 3, label: 'Wage Details' },
    { step: 4, label: 'Post Hire Details' }
  ];

  // Add these predefined images
  const predefinedImages = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
  ];

  const CoverImageSelector = ({ register, control, errors, isCreate, setValue }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [tempSelectedIndex, setTempSelectedIndex] = React.useState(null);

    // Initialize component with existing image
    useEffect(() => {
      const initializeImage = async () => {
        // Check for existing image in localStorage
        const savedStep2Data = JSON.parse(localStorage.getItem(STORAGE_KEYS.STEP_2) || '{}');

        if (savedStep2Data.jobCoverImage) {
          const imageUrl = predefinedImages[savedStep2Data.jobCoverImage.index];
          if (imageUrl) {
            const file = await urlToFile(imageUrl);
            if (file) {
              setValue('jobCoverImage', file);
              setSelectedImageIndex(savedStep2Data.jobCoverImage.index);
            }
          }
        } else if (!isCreate && moblyzeJob?.coverImageUrl) {
          const file = await urlToFile(moblyzeJob.coverImageUrl);
          if (file) {
            setValue('jobCoverImage', file);
            const index = predefinedImages.findIndex(url => url === moblyzeJob.coverImageUrl);
            setSelectedImageIndex(index !== -1 ? index : null);
          }
        }
      };

      initializeImage();
    }, [isCreate, setValue, moblyzeJob]);

    const handleOpenModal = () => {
      setTempSelectedIndex(selectedImageIndex);
      setIsOpen(true);
    };

    const handleCloseModal = () => {
      setTempSelectedIndex(selectedImageIndex);
      setIsOpen(false);
    };

    const handleSaveChanges = async () => {
      if (tempSelectedIndex !== null) {
        const imageUrl = predefinedImages[tempSelectedIndex];
        const file = await urlToFile(imageUrl);

        if (file) {
          setValue('jobCoverImage', file);
          setSelectedImageIndex(tempSelectedIndex); // Using the parent state setter

          // Save to localStorage as File properties
          const fileData = {
            name: file.name,
            lastModified: file.lastModified,
            size: file.size,
            type: file.type,
            selectedIndex: tempSelectedIndex // Store the selected index
          };

          const step2Data = JSON.parse(localStorage.getItem(STORAGE_KEYS.STEP_2) || '{}');
          localStorage.setItem(STORAGE_KEYS.STEP_2, JSON.stringify({
            ...step2Data,
            jobCoverImage: fileData
          }));
        }
      }
      setIsOpen(false);
    };

    // Get display image URL
    const displayImage = selectedImageIndex !== null
      ? predefinedImages[selectedImageIndex]
      : moblyzeJob?.coverImageUrl;

    return (
      <Box mb={'32px'}>
        <Text mb={2} fontWeight={500}>Cover Image {isCreate && <span style={{ color: 'red' }}>*</span>}</Text>
        {(selectedImageIndex !== null || (!isCreate && moblyzeJob?.coverImageUrl)) ? (
          <Stack direction="row" spacing={4} justify="flex-start" alignItems={'center'}>
            <Image
              src={displayImage}
              alt="Selected cover"
              objectFit="cover"
              width="auto"
              height="246"
              borderRadius="md"
              borderColor={colors.white}
              borderWidth={1}
              borderStyle={'solid'}
            />
            <Button
              variant="outline"
              minWidth={'inherit'}
              size="md"
              textColor={colors.white}
              _hover={{color: colors.white}}
              onClick={handleOpenModal}
            >
              Change Cover Image
            </Button>
          </Stack>
        ) : (
          <Tag
            onClick={handleOpenModal}
            width="100%"
            height="40px"
            variant="outline"
            borderStyle="solid"
            borderColor={colors.greyMedium}
            fontWeight={400}
            color={colors.greyLight}
          >
            Select Cover Image
          </Tag>
        )}

        <Modal isOpen={isOpen} onClose={handleCloseModal} size="2xl">
          <ModalOverlay />
          <ModalContent bg={colors.greyDark}>
            <ModalHeader color={colors.white}>Select Cover Image</ModalHeader>
            <ModalCloseButton color={colors.white} />
            <ModalBody pb={6}>
              <SimpleGrid columns={3} spacing={4} mb={6}>
                {predefinedImages.map((image, index) => (
                  <Box
                    key={index}
                    position="relative"
                    cursor="pointer"
                    onClick={() => setTempSelectedIndex(index)}
                    borderWidth={tempSelectedIndex === index ? '2px' : '2px'}
                    borderColor={tempSelectedIndex === index ? colors.white : colors.greyLight}
                    borderRadius="md"
                  >
                    <Image
                      src={image}
                      alt={`Cover option ${index + 1}`}
                      width="100%"
                      height="120px"
                      objectFit="cover"
                      borderColor={colors.greyLight}
                    />
                    {tempSelectedIndex === index && (
                      <Box
                        position="absolute"
                        top={'-10px'}
                        right={'-10px'}
                        width={'20px'}
                        height={'20px'}
                        bg={colors.greyDark}
                        borderRadius="100%"
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <CheckCircleIcon fontSize={'18px'} color="white" />
                     </Box>
                    )}
                  </Box>
                ))}
              </SimpleGrid>

              <Divider mb={5} />

              <Button
                width="100%"
                onClick={handleSaveChanges}
                isDisabled={tempSelectedIndex === null}
                backgroundColor={colors.white}
                color={colors.greyDark}
                _hover={{ backgroundColor: colors.white }}
                mb={4}
              >
                Save Changes
              </Button>
              <Button
                width="100%"
                variant="outline"
                onClick={handleCloseModal}
                borderColor={colors.white}
                color={colors.white}
                _hover={{ bg: 'transparent' }}
              >
                Cancel
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>

        <input
          type="hidden"
          {...register('jobCoverImage', { required: isCreate })}
        />
        {errors.jobCoverImage && (
          <Text color="red.500" fontSize="sm" mt={1}>
            Cover image is required
          </Text>
        )}
      </Box>
    );
  };

  const onStepFourSubmit = async (data) => {
    localStorage.setItem(STORAGE_KEYS.STEP_4, JSON.stringify(data));

    // Combine all data from localStorage
    const step1Data = JSON.parse(localStorage.getItem(STORAGE_KEYS.STEP_1) || '{}');
    const step2Data = JSON.parse(localStorage.getItem(STORAGE_KEYS.STEP_2) || '{}');
    const step3Data = JSON.parse(localStorage.getItem(STORAGE_KEYS.STEP_3) || '{}');
    const step4Data = JSON.parse(localStorage.getItem(STORAGE_KEYS.STEP_4) || '{}');

    const combinedData = {
      ...step1Data,
      ...step2Data,
      ...step3Data,
      ...step4Data
    };

    let coverImageFile = null;
    if (combinedData.jobCoverImage) {
      const imageUrl = predefinedImages[selectedImageIndex];
      if (imageUrl) {
        coverImageFile = await urlToFile(imageUrl);
      }
    }
    const variables = {
      bullhornId: combinedData?.bullhornId || null,
      payRateValue: combinedData?.payRateValue === "" ? null : combinedData?.payRateValue,
      payFrequency: combinedData?.payFrequencySelectedOption?.value,
      payCurrency: combinedData?.payCurrencySelectedOption?.value,
      category: combinedData?.jobCategory,
      title: combinedData?.jobPositionTitle,
      employmentType: combinedData?.employmentTypeSelectedOption?.value,
      numOpenings: combinedData?.numOpenings,
      clientOrganizationId: combinedData?.clientOrganizationSelectedOption?.value,
      employerContactName: combinedData?.clientContactName,
      employerContactEmail: combinedData?.clientContactEmail,
      employerContactPhone: combinedData?.clientContactPhone,
      agencyContactName: combinedData?.agencyContactName,
      agencyContactEmail: combinedData?.agencyContactEmail,
      agencyContactPhone: combinedData?.agencyContactPhone,
      location: combinedData?.jobLocationDescription,
      duration: combinedData?.duration,
      startDate: combinedData?.startDate ? new Date(combinedData?.startDate).toISOString() : null,
      requirementsDescription: combinedData?.moreRequirements,
      responsibilitiesDescription: combinedData?.keyResponsibilities,
      vesselId: combinedData?.vesselSelectedOption?.value,
      hiredInstructions: combinedData?.hiredInstructions,
      siteAddress: combinedData?.siteAddress,
      jobCoverImage: coverImageFile,
      candidateRoleIds: combinedData?.jobRolesSelectedOptions?.map((role) => role.value),
      candidateWorkLocationIds: combinedData?.jobLocationSelectedOptions?.map(
        (location) => location.value
      ),
      requiredSkills: combinedData?.requiredSkillsOptions?.map((skill) => skill.value),
      requiredCertifications: combinedData?.requiredCertifications?.map((cert) => cert.value),
    };

    // Submit the combined data
    try {
      if (isCreate) {
        await createJobAndUploadLogo({
          variables,
          imageFile: variables.jobCoverImage,
        });

      } else {
        variables.moblyzeJobId = moblyzeJob.id;
        await updateJobAndUploadLogo({
          variables,
          imageFile: variables.jobCoverImage,
          moblyzeJobId: moblyzeJob.id,
        });
      }

      clearFormStorage();
    } catch (error) {
      console.error('Error submitting form:', error);
    }

  };

  return (
    <Flex direction="column">
      <Text onClick={handleCancel} fontSize="md" lineHeight="6" fontWeight="normal" cursor="pointer" mb={4} alignSelf={'flex-start'}>
        <Icon fontSize="14px" position={'relative'} top={'2px'} marginRight={'2px'} as={LeftArrow} /> Back
      </Text>

      <PageTitle title={title} />

      <Breadcrumb marginTop={8} spacing="8px" separator={<ChevronRightIcon fontSize={'24px'} position={'relative'} top={'-2px'} color={colors.white} />}>
        {breadcrumbItems.map(({ step, label }) => (
          <BreadcrumbItem key={step}>
            <BreadcrumbLink
              onClick={() => handleBreadcrumbClick(step)}
              color={
                step < currentStep
                  ? colors.white // Filled/Completed steps
                  : currentStep === step
                    ? colors.white // Active step
                    : colors.greyLight // Inactive/Future steps
              }
              pointerEvents={!isCreate && step < currentStep ? 'auto' : 'none'}
              position="relative"
              fontWeight={currentStep === step ? '700' : '400'}
              fontSize={currentStep === step ? '20px' : '16px'}
            >
              {label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>

      <Divider mt={5} mb={5} />

      {/* Step 1 */}
      {currentStep === 1 && (
        <Box mr={{ base: "0px",  md: "50%", lg: "50%"}}>
          <form onSubmit={handleSubmit((data) => {
            onStepOneSubmit(data);
          })}>
            <Text fontSize='24px' fontWeight={700} marginBottom={'32px'}>Department Details</Text>
            {(currentUser?.agency?.type !== 'agency' && currentUser?.agency?.type !== '' && currentUser?.agency?.type !== 'corporate') && (
              <>
                <BasicInput
                  name="clientContactName"
                  label="Primary Contact"
                  placeholder="Firstname Lastname"
                  required={false}
                  register={register}
                  control={control}
                  errors={errors}
                  ml={'0'}
                  mb={'32px'}
                />
                <BasicInput
                  name="clientContactEmail"
                  label="Contact Email"
                  placeholder="name@example.com"
                  required={false}
                  typeOverride="email"
                  register={register}
                  control={control}
                  errors={errors}
                  ml={'0'}
                  mb={'32px'}
                  validation={{
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Invalid email address"
                    }
                  }}
                />
                <BasicInput
                  name="clientContactPhone"
                  label="Contact Phone"
                  placeholder="+1-555-555-5555"
                  required={false}
                  typeOverride="tel"
                  register={register}
                  control={control}
                  errors={errors}
                  ml={'0'}
                  mb={'32px'}
                />
              </>
            )}

            <SelectInput
              name="clientOrganizationSelectedOption"
              label="Department"
              placeholder="Select a client company"
              required
              options={clientOrganizationOptionsSorted}
              register={register}
              control={control}
              errors={errors}
              disabled={!isCreate}
              ml={'0'}
              mb={'32px'}
            />

            {/* Conditional fields based on type */}
            {currentUser?.agency?.type !== 'corporate' && (
              <>
                <BasicInput
                  name="agencyContactName"
                  label="JAB - Primary Contact Name"
                  placeholder="Firstname Lastname"
                  required={false}
                  register={register}
                  control={control}
                  errors={errors}
                  ml={'0'}
                  mb={'32px'}
                />
                <BasicInput
                  name="agencyContactEmail"
                  label="JAB - Contact Email"
                  placeholder="example@jab-recruitment.com"
                  required={false}
                  typeOverride="email"
                  register={register}
                  control={control}
                  errors={errors}
                  ml={'0'}
                  mb={'32px'}
                  validation={{
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Invalid email address"
                    }
                  }}
                />
                <BasicInput
                  name="agencyContactPhone"
                  label="JAB - Contact Phone"
                  placeholder="+1-555-555-5555"
                  required={false}
                  typeOverride="tel"
                  register={register}
                  control={control}
                  errors={errors}
                  ml={'0'}
                  mb={'32px'}
                />
                <BasicInput
                  name="bullhornId"
                  label="Bullhorn Job ID"
                  register={register}
                  control={control}
                  errors={errors}
                  ml={'0'}
                  mb={'32px'}
                />
              </>
            )}

            <Divider mt={0} mb={7} />
            <Stack spacing={4} direction="row" align="center">
              <Button variant="outline" borderColor={colors.white} textColor={colors.white} _hover={{color: colors.white}} onClick={handleCancel}>Cancel</Button>
              <Button backgroundColor={colors.white} color={colors.greyDark} _hover={{backgroundColor: colors.white}} onClick={handleNext} isDisabled={!isValid} isLoading={isSubmitting}>Next Step</Button>
            </Stack>
          </form>
        </Box>
      )}

      {/* Step 2 */}
      {currentStep === 2 && (
        <Box mr={{ base: "0px",  md: "50%", lg: "50%"}}>
          <form onSubmit={handleSubmit(onStepTwoSubmit)}>
            <Text fontSize='24px' fontWeight={700} marginBottom={'32px'}>Select Job Details</Text>
            <MultiSelectInput
              name="jobRolesSelectedOptions"
              label="Role"
              required
              options={jobRoleOptionsSorted}
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            {/* <ImageUpload
              name="jobCoverImage"
              label="Cover Image"
              placeholder="Select a cover image"
              required={isCreate}
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            /> */}

            <CoverImageSelector
              register={register}
              control={control}
              errors={errors}
              isCreate={isCreate}
              setValue={setValue}
            />

            <MultiSelectInput
              name="requiredSkillsOptions"
              label="Desired Skills"
              options={skillOptions}
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            <SelectInput
              name="employmentTypeSelectedOption"
              label="Type"
              placeholder="Job Type"
              required
              options={employmentTypeOptions}
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            <BasicInput
              name="jobLocationDescription"
              label="Description"
              required
              placeholder="London, Epsom, Bristol & Edinburgh"
              register={register}
              control={control}
              errors={errors}
              typeOverride="textarea"
              ml={'0'}
              mb={'32px'}
            />

            <MultiSelectInput
              name="jobLocationSelectedOptions"
              label="Job Locations"
              required
              options={jobLocationOptions}
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            <BasicInput
              name="startDate"
              label="Start Date"
              placeholder="Start Date"
              required
              typeOverride="date"
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            <BasicInput
              name="numOpenings"
              label="Number of Positions Availible"
              required
              typeOverride="number"
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            <MultiSelectInput
              name="requiredCertifications"
              label="Required Certifications"
              options={certificationOptions}
              register={register}
              defaultValue={certificationOptions.map((option) => ({
                value: option.value,
                label: option.label,
              }))}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            <BasicInput
              name="jobCategory"
              label="Job Title"
              required
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            <BasicInput
              name="jobPositionTitle"
              label="Position Title"
              required
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            <BasicInput
              name="duration"
              label="Duration"
              placeholder="12 Weeks"
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            <BasicInput
              name="moreRequirements"
              label="More Requirements"
              required
              typeOverride="textarea"
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            <BasicInput
              name="keyResponsibilities"
              label="Key Responsibilities"
              required
              typeOverride="textarea"
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            <Divider mt={0} mb={7} />

            <Stack spacing={4} direction="row" align="center">
              <Button variant="outline" borderColor={colors.white} textColor={colors.white} _hover={{color: colors.white}} onClick={handlePrevious}>Previous Step</Button>
              <Button backgroundColor={colors.white} color={colors.greyDark} _hover={{backgroundColor: colors.white}} onClick={handleNext} isDisabled={!isValid} isLoading={isSubmitting}>Next Step</Button>
            </Stack>
          </form>
        </Box>
      )}

      {/* Step 3 */}
      {currentStep === 3 && (
        <Box mr={{ base: "0px",  md: "50%", lg: "50%"}}>
          <form onSubmit={handleSubmit(onStepThreeSubmit)}>
            <Text fontSize='24px' fontWeight={700} marginBottom={'32px'}>Wage Details</Text>
            <SelectInput
              name="payCurrencySelectedOption"
              label="Currency"
              placeholder="Currency"
              options={payCurrencyOptions}
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            <BasicInput
              name="payRateValue"
              label="Pay Rate"
              typeOverride="number"
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            <SelectInput
              name="payFrequencySelectedOption"
              label="Frequency"
              placeholder="Frequency"
              options={payFrequencyOptions}
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            <Divider mt={0} mb={7} />
            <Stack spacing={4} direction="row" align="center">
              <Button variant="outline" borderColor={colors.white} textColor={colors.white} _hover={{color: colors.white}} onClick={handlePrevious}>Previous Step</Button>
              <Button backgroundColor={colors.white} color={colors.greyDark} _hover={{backgroundColor: colors.white}} onClick={handleNext} isDisabled={!isValid} isLoading={isSubmitting}>Next Step</Button>
            </Stack>
          </form>
        </Box>
      )}

      {/* Step 4 */}
      {currentStep === 4 && (
        <Box mr={{ base: "0px",  md: "50%", lg: "50%"}}>
          <form onSubmit={handleSubmit(onStepFourSubmit)}>
            <Text fontSize='24px' fontWeight={700} marginBottom={'32px'}>Select Post Hire Details</Text>
            <BasicInput
              name="hiredInstructions"
              label="Hired Instructions"
              typeOverride="textarea"
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />
            <BasicInput
              name="siteAddress"
              label="Site Address"
              typeOverride="textarea"
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            <Divider mt={0} mb={7} />
            <Text fontSize='20px' fontWeight={700} marginBottom={'32px'}>Vessel Details</Text>

            <SelectInput
              name="vesselSelectedOption"
              label="Vessel"
              placeholder="Select a vessel"
              options={vesselOptionsSorted}
              register={register}
              control={control}
              errors={errors}
              ml={'0'}
              mb={'32px'}
            />

            <AddVesselModal
              refetchParent={refetch}
              setNewVesselId={handleNewVessselIdSelectedFromModal}
            />

            <Divider mt={0} mb={7} />
            <Stack spacing={4} direction="row" align="center">
              <Button variant="outline" borderColor={colors.white} textColor={colors.white} _hover={{color: colors.white}} onClick={handlePrevious}>Previous Step</Button>
              <Button backgroundColor={colors.white} color={colors.greyDark} _hover={{backgroundColor: colors.white}} onClick={handleFinish} isDisabled={!isValid} isLoading={isSubmitting}>{isCreate ? 'Post Job': 'Update Job'}</Button>
            </Stack>

          </form>
        </Box>
      )}

    </Flex>
  );
}

function CreateEditJobPage({ loading, data, error, refetch, currentUser }) {
  return (
      <CreateEditJobForm
        loading={loading}
        data={data}
        error={error}
        refetch={refetch}
        currentUser={currentUser}
      />
  );
}

function PageComponent({ children, currentUser }) {
  const { id } = useParams();

  // edit mode
  if (id) {
    return WithQuery(
      CreateEditJobPage,
      JOB_EDIT_PAGE_QUERY,
      { id },
      {
        children,
        currentUser,
      }
    )();
  }

  // create mode
  return WithQuery(
    CreateEditJobPage,
    JOB_CREATE_PAGE_QUERY,
    {},
    {
      children,
      currentUser,
    }
  )();
}

export default WithAppLayout(PageComponent);
