import React from "react";
import { useNavigate } from "react-router-dom";

import WithAppLayout from "../../../components/WithAppLayout";
import PageTableWithSearch from "../../../components/PageTableWithSearch/PageTableWithSearch";

import { jobsColumns } from "../../../components/PageTableWithSearch/tableDataHelper";
import WithQuery from "../../../components/WithQuery";
import { ARCHIVED_JOBS_QUERY } from "../../../graphql/jobs";
import { JOB_DETAILS_PATH } from "../../../routes";
import { LoadingIndicator } from "../../../components/LoadingIndicator";
import __ from "../../../helpers/translations"

function ActiveJobsPageInner({ data, error, loading, refetch, currentUser }) {
  const navigate = useNavigate();

  if (loading) {
    return <LoadingIndicator />;
  }
  const agencyType = currentUser?.agency?.type;

  return (
    <PageTableWithSearch
      title={`${__("Archived", agencyType)} Jobs ${
        data?.agencyActiveJobs?.length
          ? ` (${data?.agencyActiveJobs?.length})`
          : ""
      }`}
      columns={jobsColumns}
      data={data?.agencyArchivedJobs || []}
      onClickRow={(moblyzeJob) => {
        navigate(JOB_DETAILS_PATH.replace(":id", moblyzeJob.id));
      }}
    />
  );
}

function PageComponent({ children, currentUser }) {
  return WithQuery(ActiveJobsPageInner, ARCHIVED_JOBS_QUERY, null, {
    children,
    currentUser
  })();
}

export default WithAppLayout(PageComponent);
