import MoblyzeLocalStorage, { LOCAL_STORAGE_KEYS } from "./MoblyzeLocalStorage";
// eslint-disable-next-line import/no-cycle
import MoblyzeApolloClient from "./MoblyzeApolloClient";
import { CLAIM_INVITE_MUTATION, LOGIN_MUTATION } from "../graphql/auth";
import { LoginErrorMsg, LoginErrorAccountDisabled } from "../helpers/strings";
import * as Sentry from "@sentry/react";


export async function Login({ email, password }) {
  return MoblyzeApolloClient.mutate({
    mutation: LOGIN_MUTATION,
    variables: { email, password },
  })
    .then((r) => {
      const token = r?.data?.auth?.login?.token;
      const userId = r?.data?.auth?.login?.userId;
      const role = r?.data?.auth?.login?.role;

      // Set localstorage data
      if (token && userId) {
        // Remove Analytics value to be update later.
        MoblyzeLocalStorage.remove({ key: LOCAL_STORAGE_KEYS.ANALYTICS });
        // Chain the localstorage promise
        return MoblyzeLocalStorage.save({
          key: LOCAL_STORAGE_KEYS.CURRENT_USER_KEY,
          data: {
            token,
            userId,
            role,
          },
        });
      }
      console.log("Login response userid or token is null");
      throw new Error(LoginErrorMsg);
    })
    .catch((e) => {
      Sentry.captureException(e);

      console.log("Login Error:", e);
      if (e == "ApolloError: disabled") {
        alert(LoginErrorAccountDisabled);
      } else {
        alert(LoginErrorMsg);
      }
      throw e;
    });
}

export async function ClaimInvite({ inviteToken, password }) {
  return MoblyzeApolloClient.mutate({
    mutation: CLAIM_INVITE_MUTATION,
    variables: { inviteToken, password },
  })
    .then((r) => {
      const token = r?.data?.auth?.claimInvite?.token;
      const userId = r?.data?.auth?.claimInvite?.userId;
      const role = r?.data?.auth?.claimInvite?.role;

      // Set localstorage data
      if (token && userId) {
        // Remove Analytics value to be update later.
        MoblyzeLocalStorage.remove({ key: LOCAL_STORAGE_KEYS.ANALYTICS });
        // Chain the localstorage promise
        return MoblyzeLocalStorage.save({
          key: LOCAL_STORAGE_KEYS.CURRENT_USER_KEY,
          data: {
            token,
            userId,
            role,
          },
        });
      }
      console.log("Login response userid or token is null");
      throw new Error(LoginErrorMsg);
    })
    .catch((e) => {
      Sentry.captureException(e);

      console.log("Login Error:", e);

      throw e;
    });
}

export const Logout = async () => {
  // Remove Analytics value to be update later.
  MoblyzeLocalStorage.remove({ key: LOCAL_STORAGE_KEYS.ANALYTICS });

  // Clear localstorage token
  return MoblyzeLocalStorage.remove({
    key: LOCAL_STORAGE_KEYS.CURRENT_USER_KEY,
  })
    .then(() => {
      // Clear apollo state, return chained promise
      MoblyzeApolloClient.resetStore();
      // Clear segment state
      window.analytics.reset();
      window.location.href = "/";
    })
    .catch((err) => {
      Sentry.captureException(err);

      console.log("Error in MoblyzeAuth.Logout", err);
      throw err;
    });
};

export async function GetLocalAuthData() {
  let localAuthData = null;

  await MoblyzeLocalStorage.load({
    key: LOCAL_STORAGE_KEYS.CURRENT_USER_KEY,
  })
    .then((ret) => {
      if (!ret) {
        return null;
      }

      localAuthData = {};
      localAuthData.token = ret?.token;
      localAuthData.userId = ret?.userId;
      localAuthData.role = ret?.role;
    })
    .catch((err) => {
      Sentry.captureException(err);

      console.log(err.message);
    });

  return localAuthData;
}

export async function GetLocalAuthToken() {
  const localAuthData = await GetLocalAuthData();
  return localAuthData ? localAuthData?.token : null;
}

export async function GetLocalAuthRole() {
  const localAuthData = await GetLocalAuthData();
  return localAuthData ? localAuthData?.role : null;
}

export async function IsAuthd() {
  const token = await GetLocalAuthToken();
  return !!token;
}

export async function HasAnalytics() {
  const loadAnalyticsData = await MoblyzeLocalStorage.load({
    key: LOCAL_STORAGE_KEYS.ANALYTICS,
  });
  return loadAnalyticsData === true;
}