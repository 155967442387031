import { Flex, Stack, Text } from "@chakra-ui/react";

import { MdAdd } from "react-icons/md";

import { NavButton } from "./NavButton";
import colors from "../helpers/colors";
import __ from "../helpers/translations"
import { STORAGE_KEYS } from '../constants/storage'; // You'll need to create this file

import {
  UNCURATED_JOBS_PATH,
  ACTIVE_JOBS_PATH,
  ARCHIVED_JOBS_PATH,
  ADD_JOB_PATH,
  ALL_CLIENTS_PATH,
  ADD_CLIENT_PATH,
  ALL_RECRUITERS_PATH,
  IMPORT_JOBS_PATH,
  ALL_CANDIDATES_PATH,
  INVITE_CANDIDATE_PATH,
  AGENCIES_PATH,
} from "../routes";
import { AgencyRecruiterShareNavButton } from "./Shortlist/AgencyShareActions";
import { useNavigate } from "react-router-dom";

export default function Sidebar({ currentUser }) {
  const navigate = useNavigate();
  const agencyType = currentUser?.agency?.type;

  const renderStackLabel = (label) => {
    return (
      <Text fontSize="16px" color={colors.white} fontWeight="700" px="4">
        {__(label, agencyType)}
      </Text>
    );
  };

  const uncurateJobsCount =
    currentUser?.recruiterOrganizationReport?.uncuratedJobsCount || null;


  const clearCreateJobStorage = () => {
    Object.values(STORAGE_KEYS).forEach(key => {
        localStorage.removeItem(key);
    });
  };

  return (
    <Flex
      py="8"
      style={{ borderRight:`1px solid  ${colors.greyMedium}`}}
      bg = {"var(--greyDark)"}
    >
      <Stack justify="space-between" spacing="0" width="700">
        <Stack spacing="8" shouldWrapChildren>
          <Stack>
            {currentUser?.isOwner && (
              <NavButton
                fontSize="16px" color={colors.white} fontWeight="700"
                label={__('Agencies', agencyType)}
                path={AGENCIES_PATH}
              />
            )}
          </Stack>

          <Stack>
            {renderStackLabel('Jobs')}
            <Stack spacing="0">
              <NavButton
                label={__('Uncurated', agencyType)}
                count={uncurateJobsCount}
                showDot={uncurateJobsCount && uncurateJobsCount > 0}
                path={UNCURATED_JOBS_PATH}
              />
              <NavButton
                label={__('Active', agencyType)}
                showDot={true}
                path={ACTIVE_JOBS_PATH}
              />
              <NavButton label={__('Archived', agencyType)} path={ARCHIVED_JOBS_PATH} />
              <NavButton
                label={__('Add New Job', agencyType)}
                icon={MdAdd}
                path={ADD_JOB_PATH}
                onClick={() => {
                  clearCreateJobStorage();
                  navigate(ADD_JOB_PATH);
                }}
              />
              {agencyType !== "corporate" &&
                <NavButton
                  label={__('Import Jobs', agencyType)}
                  icon={MdAdd}
                  path={IMPORT_JOBS_PATH}
                />
              }
            </Stack>
          </Stack>
          <Stack>
            {renderStackLabel("Clients")}
            <Stack spacing="0">
              <NavButton label={__('All', agencyType)} path={ALL_CLIENTS_PATH} />
              <NavButton
                label={__('Add New Client', agencyType)}
                icon={MdAdd}
                path={ADD_CLIENT_PATH}
              />
            </Stack>
          </Stack>
          <Stack>
            {renderStackLabel("Admins")}
            <Stack spacing="0">
              <NavButton label={__('All', agencyType)} path={ALL_RECRUITERS_PATH} />
              <AgencyRecruiterShareNavButton />
            </Stack>
          </Stack>
          <Stack>
            {renderStackLabel("Workers")}
            <Stack spacing="0">
              <NavButton label={__('All', agencyType)} path={ALL_CANDIDATES_PATH} />
              <NavButton
                label={__('Invite', agencyType)}
                icon={MdAdd}
                path={INVITE_CANDIDATE_PATH}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
}
