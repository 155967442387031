import React from "react";
import { useNavigate } from "react-router-dom";

import WithAppLayout from "../../../components/WithAppLayout";
import PageTableWithSearch from "../../../components/PageTableWithSearch/PageTableWithSearch";

import { clientOrgColumns } from "../../../components/PageTableWithSearch/tableDataHelper";
import WithQuery from "../../../components/WithQuery";
import { ALL_CLIENT_ORGS_QUERY } from "../../../graphql/clients";
import { CLIENT_DETAILS_PATH } from "../../../routes";
import { LoadingIndicator } from "../../../components/LoadingIndicator";
import __ from "../../../helpers/translations"

function AllClientsPage({ data, error, loading, refetch, currentUser }) {
  const navigate = useNavigate();

  if (loading) {
    return <LoadingIndicator />;
  }
  const agencyType = currentUser?.agency?.type;

  return (
    <PageTableWithSearch
      title={`All ${__("Clients", agencyType)} ${
        data?.getClientOrganizations?.length
          ? ` (${data?.getClientOrganizations?.length})`
          : ""
      }`}
      columns={clientOrgColumns}
      data={data?.getClientOrganizations || []}
      onClickRow={(clientOrg) => {
        navigate(CLIENT_DETAILS_PATH.replace(":id", clientOrg.id));
      }}
    />
  );
}

function PageComponent({ children, currentUser }) {
  return WithQuery(AllClientsPage, ALL_CLIENT_ORGS_QUERY, null, {
    children,
    currentUser,
  })();
}

export default WithAppLayout(PageComponent);
