import React from "react";
import { useNavigate } from "react-router-dom";

import WithAppLayout from "../../../components/WithAppLayout";
import PageTableWithSearch from "../../../components/PageTableWithSearch/PageTableWithSearch";

import { candidatesColumns } from "../../../components/PageTableWithSearch/tableDataHelper";
import WithQuery from "../../../components/WithQuery";
import { GET_CANDIDATE_USERS_QUERY } from "../../../graphql/agencies";
import { EDIT_CANDIDATE_PATH } from "../../../routes";
import { LoadingIndicator } from "../../../components/LoadingIndicator";
import __ from "../../../helpers/translations";

function AllCandidatesPageInner({
  data,
  error,
  loading,
  refetch,
  currentUser,
}) {
  const navigate = useNavigate();

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <PageTableWithSearch
      title={__("Workers", currentUser?.agency?.type)}
      columns={candidatesColumns}
      data={data?.agencyCandidateUsers || []}
      onClickRow={(candidate) => {
        navigate(EDIT_CANDIDATE_PATH.replace(":id", candidate.id));
      }}
    />
  );
}

function PageComponent({ children, currentUser }) {
  return WithQuery(AllCandidatesPageInner, GET_CANDIDATE_USERS_QUERY, null, {
    children,
    currentUser,
  })();
}

export default WithAppLayout(PageComponent);
