import * as React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  IconButton,
  Flex,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  useToast,
  Text,
  Badge,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import MoblyzeApolloClient from "../../services/MoblyzeApolloClient";

import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";
import { CURATE_JOB_PATH } from "../../routes";
import { AGENCY_ARCHIVE_BULLHORN_JOB_MUTATION } from "../../graphql/agencies";

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export const EditUncuratedJobRowAction = ({ row, refetch }) => {
  const navigate = useNavigate();
  const toast = useToast();

  const archiveJob = (e) => {
    e?.stopPropagation();

    MoblyzeApolloClient.mutate({
      mutation: AGENCY_ARCHIVE_BULLHORN_JOB_MUTATION,
      variables: {
        bullhornJobId: row?.original?.id,
      },
    })
      .then((result) => {
        if (refetch) {
          refetch();
        }

        toast({
          title: "Job archived",
          status: "success",
        });
      })
      .catch((e) => {
        Sentry.captureException(e);

        alert(e.message);
      });
  };

  return (
    <Menu onClick={(e) => e?.stopPropagation()}>
      <MenuButton
        as={Button}
        variant="ghost"
        colorScheme="white"
        onClick={(e) => e?.stopPropagation()}
      >
        <Icon fontSize={30} as={MenuIcon} cursor="pointer" />
      </MenuButton>
      <MenuList>
        <MenuItem
          onClick={() => {
            navigate(CURATE_JOB_PATH.replace(":id", row.id));
          }}
        >
          Curate Job
        </MenuItem>
        <MenuItem onClick={archiveJob}>Archive Job</MenuItem>
      </MenuList>
    </Menu>
  );
};

const renderNewCountBadge = (count) => {
  if (!count || count === 0) {
    return null;
  }

  return (
    <Badge
      colorScheme="brand"
      variant="solid"
      fontSize="8px"
      pr="6px"
      pl="6px"
      pt="3px"
      pb="3px"
      lineHeight="8px"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      display="flex"
      ml="8px"
      borderRadius="10px"
    >
      {count} New
    </Badge>
  );
};

export const RecruiterJobReportInterestedColumn = ({ row }) => {
  const recruiterJobReport = row?.original?.recruiterJobReport;

  if (
    !recruiterJobReport?.interestedCount ||
    recruiterJobReport?.interestedCount === 0
  ) {
    return "-";
  }

  return (
    <Flex flexDir="row">
      <Text>{recruiterJobReport?.interestedCount}</Text>
      {renderNewCountBadge(recruiterJobReport?.unviewedInterestedCount)}
    </Flex>
  );
};

export const RecruiterJobReportApprovedColumn = ({ row }) => {
  const recruiterJobReport = row?.original?.recruiterJobReport;

  if (
    !recruiterJobReport?.approvedByClientCount ||
    recruiterJobReport?.approvedByClientCount === 0
  ) {
    return "-";
  }

  return (
    <Flex flexDir="row">
      <Text>{recruiterJobReport?.approvedByClientCount}</Text>
      {renderNewCountBadge(recruiterJobReport?.approvedByClientUnviewedCount)}
    </Flex>
  );
};

export default function DataTable({
  data,
  columns,
  searchInput,
  onClickRow,
  initialSort = [],
}) {
  const initialSortMemo = React.useMemo(() => initialSort, [initialSort]);

  const [sorting, setSorting] = React.useState(initialSortMemo);

  const [globalFilter, setGlobalFilter] = React.useState("");

  // if sorting is cleared, reset to initial sort
  React.useEffect(() => {
    if (sorting.length === 0 && initialSortMemo.length > 0) {
      setSorting(initialSortMemo);
    }
  }, [sorting, initialSortMemo]);

  // if search input changes, update global filter
  React.useEffect(() => {
    setGlobalFilter(searchInput);
  }, [searchInput]);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    state: {
      sorting,
      globalFilter,
    },
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setGlobalFilter,
  });

  return (
    <Table style={{
        border: '1px solid #898989',
        borderRadius: "12px",
        overflow: "hidden",
        backgroundColor: '#898989'
      }}>
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const meta = header.column.columnDef.meta;
              return (
                <Th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  isNumeric={meta?.isNumeric}
                  sx={{
                    fontSize: '15px',
                    fontWeight: "600",
                    borderBottom: '1px solid #898989',
                    textTransform: 'capitalize'
                  }}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}

                  <chakra.span pl="4">
                    {header.column.getIsSorted() ? (
                      header.column.getIsSorted() === "desc" ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <Tr
            key={row.id}
            cursor={onClickRow ? "pointer" : "default"}
            onClick={onClickRow ? () => onClickRow(row?.original, searchInput) : null}
          >
            {row.getVisibleCells().map((cell) => {
              const meta = cell.column.columnDef.meta;
              return (
                <Td
                  key={cell.id}
                  isNumeric={meta?.isNumeric}
                  style={{
                    width: cell.column.getSize(),
                  }}
                  sx={{
                    fontSize: '14px',
                    fontWeight: "400",
                    borderBottom: '1px solid #898989'
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              );
            })}
          </Tr>
        ))}
        {/* display message if empty */}
        {table.getRowModel().rows.length === 0 && (
          <Tr>
            <Td colSpan={columns.length}>No records</Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
}
