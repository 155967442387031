import React, { Fragment, useCallback, useState , useEffect} from "react";
import { useParams } from "react-router-dom";
import { useQuery, useApolloClient } from "@apollo/client";
import { Flex, Icon, Text, Box } from "@chakra-ui/react";
import _ from "lodash";
import { debounce } from 'lodash';

import { ReactComponent as ArrowRight } from "../../assets/icons/right-arrow.svg";
import { ReactComponent as Like } from "../../assets/icons/like.svg";
import { ReactComponent as Dislike } from "../../assets/icons/dislike.svg";
import {
  CandidateTriageStatusApprovedByClient,
  CandidateTriageStatusApprovedByRecruiter,
  CandidateTriageStatusPassedByClient,
} from "../../graphql/jobs";

import colors from "../../helpers/colors";
import { CLIENT_CANDIDATE_SKILLS_QUERY } from "../../graphql/jobs";
import { GET_SKILLS_QUERY } from "../../graphql/jobs_admin";

function calculateSkillMatch(jobSkills, candidateSkills) {
  if (!jobSkills?.length || !candidateSkills?.length) {
    return {
      matchPercentage: 0,
      color: 'red'
    };
  }

  const jobSkillNames = jobSkills.map(skill => skill.name.toLowerCase());
  const candidateSkillNames = candidateSkills.map(skill => skill.name.toLowerCase());
  
  const matchingSkills = jobSkillNames.filter(skill => 
    candidateSkillNames.includes(skill)
  ).length;

  const totalJobSkills = jobSkills.length;
  const matchPercentage = totalJobSkills > 0 ? (matchingSkills / totalJobSkills) * 100 : 0;
  const roundedMatchPercentage = Math.round(matchPercentage);

  return {
    matchPercentage: roundedMatchPercentage,
    color: getMatchColor(roundedMatchPercentage)
  };
}

function getMatchColor(percentage) {
  if (percentage >= 75) {
    return '#48BB78'; // green
  } else if (percentage >= 50) {
    return '#ED8936'; // orange
  } else {
    return '#E53E3E'; // red
  }
}

function CandidateListItem({ candidateReportList = [], candidateTriageList = [], select, setSelect, isClient, markViewedByRecruiterType, moblyzeJob}) {
  const  moblyzeJobId  = useParams(moblyzeJob);
  const [allSkillsData, setAllSkillsData] = useState({});
  const client = useApolloClient();

  const pendingRequestsRef = React.useRef(new Set());

  useEffect(() => {
    let isMounted = true;

    if (!candidateReportList?.length && !candidateTriageList?.length) {
      return;
    }

    const debouncedFetchSkills = debounce(async () => {
      if (!isMounted) return;

      const fetchSkillsForCandidate = async (profileId) => {
        if (pendingRequestsRef.current.has(profileId)) return null;
        
        pendingRequestsRef.current.add(profileId);
        
        try {
          const result = await client.query({
            query: CLIENT_CANDIDATE_SKILLS_QUERY,
            variables: { candidateProfileId: profileId },
            fetchPolicy: 'cache-first'
          });
          
          return { 
            profileId, 
            data: result.data?.getCandidateSkillsByProfileId || [] 
          };
        } catch (err) {
          console.error('Failed to fetch skills for profileId:', profileId, err);
          return null;
        } finally {
          pendingRequestsRef.current.delete(profileId);
        }
      };

      const processedIds = new Set();
      const batchPromises = [];
      
      const processCandidateList = (list) => {
        list?.forEach(candidate => {
          const profileId = candidate?.candidateUser?.candidateProfile?.id;
          if (profileId && !processedIds.has(profileId)) {
            processedIds.add(profileId);
            batchPromises.push(fetchSkillsForCandidate(profileId));
          }
        });
      };

      processCandidateList(candidateReportList);
      processCandidateList(candidateTriageList);

      const batchSize = 5;
      for (let i = 0; i < batchPromises.length; i += batchSize) {
        const batch = batchPromises.slice(i, i + batchSize);
        const results = await Promise.all(batch);
        
        if (isMounted) {
          setAllSkillsData(prev => {
            const newData = { ...prev };
            results.forEach(result => {
              if (result) {
                newData[result.profileId] = result.data;
              }
            });
            return newData;
          });
        }
      }
    }, 250);

    debouncedFetchSkills();

    return () => {
      isMounted = false;
      debouncedFetchSkills.cancel();
    };
  }, [candidateReportList, candidateTriageList, client]);

  const { data: skillsData } = useQuery(GET_SKILLS_QUERY, {
    variables: {
      moblyzeJobId: moblyzeJobId?.id,
    },
    skip: !moblyzeJobId?.id,
  });

  const jobSkills = skillsData?.getSkills || [];

  const borderRadius = useCallback(
    (index) => {
      if (candidateReportList?.length === 1) {
        return "5px 5px 5px 5px";
      } else if (index === 0) {
        return "5px 5px 0px 0px";
      } else if (index === candidateReportList?.length - 1) {
        return "0px 0px 5px 5px";
      } else {
        return "initial";
      }
    },
    [candidateReportList]
  );

  const borderBottom = useCallback(
    (index) => {
      if (index < candidateReportList?.length - 1) {
        return "1px solid #414141";
      } else {
        return "initial";
      }
    },
    [candidateReportList]
  );

  if (!candidateReportList && !candidateTriageList) {
    return null;
  }

  const renderClientShortlistStatus = ({
    candidate,
    candidateTriage,
    candidateReport,
  }) => {
    const rightArrow = (
      <Flex height="100%" alignItems="center">
        <Icon as={ArrowRight} />
      </Flex>
    );

    if (!isClient) {
      // render for recruiter

      if (candidate.id === select?.id) {
        return <Fragment>{rightArrow}</Fragment>;
      }

      if (!!candidateReport && markViewedByRecruiterType !== "") {
        // render for recruiter
        if (candidateReport?.viewedByRecruiter === false) {
          return (
            <div
              style={{
                width: "6px",
                height: "6px",
                borderRadius: "50%",
                backgroundColor: colors.brandBlue,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          );
        }
      }

      return null;
    }

    return (
      <Fragment>
        {candidateTriage?.status === CandidateTriageStatusApprovedByClient && (
          <Flex height="100%" alignItems="center">
            <Icon fontSize="36px" marginRight="20px" as={Like} />
          </Flex>
        )}
        {candidateTriage?.status === CandidateTriageStatusPassedByClient && (
          <Flex height="100%" alignItems="center">
            <Icon fontSize="36px" marginRight="20px" as={Dislike} />
          </Flex>
        )}
        {candidateTriage?.status ===
          CandidateTriageStatusApprovedByRecruiter && (
          <Flex height="100%">
            <Text
              position="absolute"
              right="24px"
              fontSize="12px"
              fontWeight="400"
              fontStyle="italic"
              lineHeight="16px"
              color={colors.charcoalText}
              pr="5"
            >
              Review Candidate
            </Text>
          </Flex>
        )}
        {candidateTriage?.id === select?.id && rightArrow}
      </Fragment>
    );
  };

  const renderCandidateReportListItem = ({ candidate, candidateReport, index }) => {
    if (!candidate) return null;

    const profileId = candidate?.candidateProfile?.id;
    const candidateSkills = allSkillsData[profileId]?.reduce((allSkills, rolePreference) => {
      return [...allSkills, ...(rolePreference.skills || [])];
    }, []) || [];

    const { matchPercentage, color } = calculateSkillMatch(jobSkills, candidateSkills);

    return (
      <Flex
        key={candidate.id}
        cursor="pointer"
        onClick={() => setSelect(candidate)}
        p="30px 14px"
        gap="14px"
        background={candidate.id === select?.id ? "gray.800" : "gray.900"}
        borderRadius={borderRadius(index)}
        borderBottom={borderBottom(index)}
        alignItems="center"
      >
        <Box
          style={{
            backgroundImage: `url(${
              candidate?.profileImageUrl ||
              "https://ui-avatars.com/api/?name=" +
                candidate?.fullName.replace(/ /g, "+") +
                "&background=random&rounded=true"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "60px",
            width: "60px",
            borderRadius: "60px",
          }}
          alt="User"
          mr="2"
          ml="2"
        />
        <Flex flex={1} direction="column" justifyContent={"center"}>
          <Text fontSize="lg" fontWeight="semibold">
            {candidate?.fullName}
          </Text>
          <Text fontSize="sm" fontWeight="medium">
            Skills Match: <span style={{ color, fontWeight: 'bold' }}>{matchPercentage}%</span>
          </Text>
        </Flex>
        {renderClientShortlistStatus({ candidate, candidateReport })}
      </Flex>
    );
  };

  const renderCandidateTriageListItem = ({ candidate, candidateTriage, index }) => {
    if (!candidateTriage) return null;

    const profileId = candidate.candidateProfile.id;
    const candidateSkills = allSkillsData[profileId]?.reduce((allSkills, rolePreference) => {
      return [...allSkills, ...(rolePreference.skills || [])];
    }, []) || [];

    const { matchPercentage, color } = calculateSkillMatch(jobSkills, candidateSkills);

    return (
      <Flex
        key={candidateTriage.id}
        cursor="pointer"
        onClick={() => setSelect(_.cloneDeep(candidateTriage))}
        p="30px 14px"
        gap="14px"
        background={candidateTriage.id === select?.id && "#2C323D"}
        borderRadius='8px'
        borderBottom='0px'
      >
        <Box
          style={{
            backgroundImage: `url(${
              candidate?.profileImageUrl ||
              "https://ui-avatars.com/api/?name=" +
                candidate?.fullName.replace(/ /g, "+") +
                "&background=random&rounded=true"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "60px",
            width: "60px",
            borderRadius: "60px",
          }}
          alt="User"
          mr="2"
          ml="2"
        />
        <Flex flex={1} direction="column" justifyContent={"center"}>
          <Text fontSize="lg" fontWeight="semibold">
            {candidate?.fullName}
          </Text>
          <Text fontSize="sm" fontWeight="medium">
            Skills Match: <span style={{ color, fontWeight: 'bold' }}>{matchPercentage}%</span>
          </Text>
        </Flex>
        {renderClientShortlistStatus({ candidate, candidateTriage })}
      </Flex>
    );
  };

  return (
    <Flex position="relative" w="435px" h="100%" direction="column">
      {candidateReportList?.map((candidateReport, i) =>
        renderCandidateReportListItem({ 
          candidate: candidateReport.candidateUser, 
          candidateReport, 
          index: i 
        })
      )}
      {candidateTriageList?.map((candidateTriage, i) =>
        renderCandidateTriageListItem({
          candidate: candidateTriage.candidateUser,
          candidateTriage,
          index: i,
        })
      )}
    </Flex>
  );
}

export default CandidateListItem;
