import { gql } from "@apollo/client";

import { CANDIDATE_USER_FRAGMENT } from "./jobs";

export const INVITE_RECRUITER_URL_MUTATION = gql`
  mutation InviteRecruiterContactUrlMutation(
    $firstName: String!
    $lastName: String!
    $email: String!
  ) {
    inviteRecruiterContactUrl(
      firstName: $firstName
      lastName: $lastName
      email: $email
    )
  }
`;

export const GET_RECRUITER_USERS_QUERY = gql`
  query GetRecruiterUsersQuery {
    getRecruiterUsers {
      id
      firstName
      lastName
      email
    }
  }
`;

export const GET_CANDIDATE_USERS_QUERY = gql`
  ${CANDIDATE_USER_FRAGMENT}
  query AgencyCandidateUsers {
    agencyCandidateUsers {
      ...CandidateUserFragment
    }
  }
`;

export const GET_CANDIDATE_USER_QUERY = gql`
  ${CANDIDATE_USER_FRAGMENT}
  query AgencyCandidateUser($id: ID!) {
    agencyCandidateUser(id: $id) {
      ...CandidateUserFragment
    }
  }
`;

export const AGENCIES_QUERY = gql`
  query Agencies {
    agencies {
      id
      name
      type
      logoUrl
      slugId
      disabledAt
    }
  }
`;

export const CREATE_AGENCY_MUTATION = gql`
  mutation CreateAgencyMutation($name: String!, $agencyType: String!, $logo: Upload!) {
    createAgency(name: $name, agencyType: $agencyType, logo: $logo) {
      id
      name
      type
      logoUrl
      slugId
    }
  }
`;

export const UPDATE_AGENCY_MUTATION = gql`
  mutation UpdateAgencyMutation($slugId: String!, $name: String!, $agencyType: String!, $logo: Upload) {
    updateAgency(slugId: $slugId, name: $name, agencyType: $agencyType, logo: $logo) {
      id
      name
      type
      logoUrl
      slugId
    }
  }
`;

export const UPDATE_AGENCY_STATUS_MUTATION = gql`
  mutation UpdateAgencyStatusMutation($slugId: String!, $status: String!) {
    updateAgencyStatus(slugId: $slugId, status: $status) {
      id
      disabledAt
      slugId
    }
  }
`;

export const UPDATE_CANDIDATE_PROFILE_MUTATION = gql`
  mutation UpdateCandidateProfileMutation(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $primaryPhone: String!
  ) {
    updateCandidateProfile(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      primaryPhone: $primaryPhone
    ) {
      id
    }
  }
`;

export const UPLOAD_CANDIDATE_RESUME_MUTATION = gql`
  mutation UploadCandidateResumeMutation(
    $candidateUserId: ID!
    $resumeFile: Upload!
  ) {
    uploadCandidateResume(
      candidateUserId: $candidateUserId
      resumeFile: $resumeFile
    ) {
      id
    }
  }
`;

export const INVITE_CANDIDATE_MUTATION = gql`
  mutation InviteCandidateMutation(
    $bullhornId: String
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
  ) {
    inviteCandidate(
      bullhornId: $bullhornId
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
    ) {
      id
    }
  }
`;

export const UPLOAD_CANDIDATE_IMPORT_RESUME_MUTATION = gql`
  mutation UploadCandidateProfileImportResumeMutation(
    $candidateProfileImportId: ID!
    $resumeFile: Upload!
  ) {
    uploadCandidateProfileImportResume(
      candidateProfileImportId: $candidateProfileImportId
      resumeFile: $resumeFile
    )
  }
`;

export const AGENCY_ARCHIVE_BULLHORN_JOB_MUTATION = gql`
  mutation CloseJob($bullhornJobId: ID!) {
    archiveBullhornJob(bullhornJobId: $bullhornJobId)
  }
`;

export const RECRUITER_VIEWED_CANDIDATE_FOR_JOB_MUTATION = gql`
  mutation RecruiterViewedCandidateForJobMutation(
    $candidateUserId: ID!
    $moblyzeJobId: ID!
    $recruiterViewType: RecruiterCandidateViewType!
  ) {
    recruiterViewedCandidateForJob(
      candidateUserId: $candidateUserId
      moblyzeJobId: $moblyzeJobId
      recruiterViewType: $recruiterViewType
    )
  }
`;
